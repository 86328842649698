import { DateString } from './types';

export enum PayoutMethod {
  ACHDirectDeposit = 'ACHDirectDeposit',
  CheckrPayBankAccount = 'CheckrPayBankAccount',
  PushToCardDirectDeposit = 'PushToCardDirectDeposit',
}

// Payouts:
export enum PayoutStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Queued = 'queued',
}

export enum PayoutQueuingReasons {
  WaitingOnManualIssuance = 'waiting_on_manual_issuance',
  WaitingOnWorkerAccountActivation = 'waiting_on_worker_account_activation',
  WaitingOnWorkerAccountReactivation = 'waiting_on_worker_account_reactivation',
}

export enum PayoutFailureReasons {
  // eslint-disable-next-line max-len
  InvalidPushToCardDirectDepositPayoutDestination = 'invalid_push_to_card_direct_deposit_payout_destination',
  InsufficientFunds = 'insufficient_funds',
}

export interface Payout {
  id: string;
  workerId: string;
  createdAt: DateString;
  amountCents: number;
  feeCents: number;
  description: string;
  completedAt?: DateString;
  failureReasons?: PayoutFailureReasons[];
  status: PayoutStatus;
  workerResource?: Worker;
  queuingReason?: PayoutQueuingReasons;
  issuanceStrategy: {
    name: 'manual' | 'instant';
    autoIssueOn?: Date;
  };
}
